import { apiClient } from "../client";

export type SuccessPayResult = {
	orderId: number;
	message: string;
};

export async function successPay(args: {
	uid: string;
	oid: number;
	bid: string;
}) {
	return apiClient.post<SuccessPayResult>("/v2/order/success_pay", args, {
		auth: true,
	});
}

export type FailPayResult = {
	orderId: number;
	message: string;
	advice: string;
};

export async function failPay(args: {
	uid: string;
	oid: number;
	bid: string;
}) {
	return apiClient.post<FailPayResult>("/v2/order/fail_pay", args, {
		auth: true,
	});
}
