export enum CartItemType {
	Category = "Category",
	Seat = "Seat",
}

export type CartItem = {
	key: string;
	type: CartItemType;
	time: number;
	name: string;
	subHeading?: string;
	price: number;
	id: number;
	eventId: number;
	tariffName?: string;
	tariffId?: number;
	groupId?: string;
	qty: number;
	limitId?: string;
	fanId?: string;
	error?: string;
	action: {
		name: string;
		address: string;
		date: Date;
	};
};

export type ItemPayload = CartItem & {
	action: {
		name: string;
		// address: string;
		date: Date;
	};
};
