import { apiClient } from "../client";

type GetProfile = {
	name: string;
	phone: string;
	email: string;
	emailConfirmed: boolean;
	nextEmailConfirmationAvailableAt: string | null;
	alfaIdConnected: boolean;
	alfaAuthDeclined: boolean;
};

export async function getProfile() {
	return apiClient.post<GetProfile>("/profile", {}, { auth: true });
}

export type UpdateProfileArgs = {
	name?: string;
	phone?: string;
	email?: string;
};

export async function updateProfile(args: UpdateProfileArgs) {
	return apiClient.put<GetProfile>("/profile", args, { auth: true });
}

export type Profile = Awaited<ReturnType<typeof getProfile>>;
