import ReactDOM from "react-dom/client";

import { initErrorTracker } from "@/shared/error";
import { patchFetch } from "@/shared/utils";

import { Providers } from "./providers";

import "./styles/main.css";

patchFetch();

initErrorTracker();

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(<Providers />);
