import { createBrowserRouter } from "react-router";

import { Component as AppWrapper } from "@/pages/_layout/app";
import { ErrorBoundary } from "@/shared/ui/error";
import { ENV } from "@/shared/constants";

export const router = createBrowserRouter([
	{
		element: <AppWrapper />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				children: [
					// Onboarding page (dev only)
					ENV !== "prod"
						? { path: "/", lazy: () => import("src/pages/onboarding-dev") }
						: {},
					// Auth callback routes
					{
						path: "/alfa-auth/:guid",
						lazy: () => import("src/pages/auth-guid"),
					},
					{
						path: "/alfa/id",
						lazy: () => import("src/pages/auth-alfa-id"),
					},
					// Email confirmation
					{
						path: "/email/confirmation",
						lazy: () => import("src/pages/email-confirmation"),
					},
					// Promo routes
					{
						path: "/alfa_friday",
						lazy: () => import("src/pages/promo-alfa_friday"),
					},
				],
			},
			{
				lazy: () => import("@/pages/_layout/main"),
				shouldRevalidate: () => false,
				children: [
					{
						path: "/city",
						lazy: () => import("src/pages/onboarding-city"),
					},
					{
						path: "/account",
						lazy: () => import("src/pages/account"),
					},
					{
						path: "/profile",
						lazy: () => import("src/pages/profile"),
					},
					{
						path: "/faq",
						lazy: () => import("src/pages/docs-faq"),
					},
					{
						path: "/docs",
						lazy: () => import("src/pages/docs-rules"),
					},
					{
						lazy: () => import("@/pages/_layout/actions"),
						shouldRevalidate: ({ currentParams, nextParams }) => {
							return currentParams.cid !== nextParams.cid;
						},
						children: [
							{
								path: "/city/:cid",
								lazy: () => import("@/pages/city"),
								// @ts-ignore
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.cid !== nextParams.cid;
								},
							},
							{
								lazy: () => import("@/pages/_layout/filters"),
								children: [
									{
										path: "/city/:cid/filters",
										lazy: async () => import("@/pages/filters"),
									},
									{
										path: "/city/:cid/filters/city",
										lazy: () => import("src/pages/filters-city"),
									},
									{
										path: "/city/:cid/filters/genres",
										lazy: () => import("src/pages/filters-genres"),
									},
									{
										path: "/city/:cid/filters/venues",
										lazy: () => import("src/pages/filters-venues"),
									},
									{
										path: "/city/:cid/filters/date",
										lazy: () => import("src/pages/filters-date"),
									},
								],
							},
							{
								path: "/city/:cid/date",
								lazy: () => import("src/pages/calendar"),
							},
							{
								path: "/city/:cid/search",
								lazy: () => import("@/pages/search"),
							},
							{
								path: "/city/:cid/:action",
								lazy: () => import("src/pages/action-details"),
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.action !== nextParams.action;
								},
							},
							{
								path: "/city/:cid/:action/reservation/:eventId",
								lazy: () => import("@/pages/reservation"),
							},
							{
								path: "/city/:cid/cinema/:action",
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.action !== nextParams.action;
								},
								lazy: () => import("src/pages/cinema-action"),
							},
							{
								id: "cinema_events",
								lazy: () => import("@/pages/_layout/cinema-events"),
								children: [
									{
										path: "/city/:cid/cinema/:action/events",
										lazy: () => import("src/pages/cinema-events"),
									},
									{
										path: "/city/:cid/cinema/:action/events/date",
										lazy: () => import("@/pages/cinema-events/date"),
									},
									{
										path: "/city/:cid/cinema/:action/reservation/:eventId",
										lazy: () => import("src/pages/cinema-reservation"),
									},
								],
							},
							{
								path: "/city/:cid/cinema/venues",
								lazy: () => import("src/pages/cinema-venues"),
							},
							{
								path: "/city/:cid/cinema/venues/:vid",
								lazy: () => import("src/pages/cinema-venue"),
							},
							{
								path: "/city/:cid/cinema/venues/:vid/map",
								lazy: () => import("src/pages/cinema-venue-map"),
							},
						],
					},
					// Checkout pages
					{
						id: "checkout",
						shouldRevalidate: () => false,
						lazy: () => import("@/pages/_layout/checkout-layout"),
						children: [
							{
								path: "/checkout",
								lazy: () => import("@/pages/checkout"),
							},
							{
								lazy: () => import("@/pages/_layout/visitors-layout"),
								children: [
									{
										path: "/checkout/visitors",
										lazy: () => import("@/pages/visitors"),
									},
									{
										path: "/checkout/visitors/:sid",
										lazy: () => import("@/pages/visitor-edit"),
									},
								],
							},
						],
					},
					{
						path: "/city/:cid/selections/:sid",
						lazy: () => import("@/pages/selections"),
					},
					{
						path: "/orders",
						lazy: () => import("src/pages/orders"),
					},
					{
						id: "tickets",
						lazy: async () => {
							const { loader } = await import("src/pages/order-details");
							return { loader };
						},
						shouldRevalidate: () => false,
						children: [
							{
								path: "/orders/:oid",
								lazy: async () => {
									const { Component } = await import("src/pages/order-details");
									return { Component };
								},
							},
							{
								path: "/orders/:oid/refund",
								lazy: () => import("src/pages/refund-tickets"),
							},
							{
								path: "/orders/:oid/refund-reason",
								lazy: () => import("src/pages/refund-reason"),
							},
							{
								path: "/orders/:oid/automatic-refund",
								lazy: () => import("src/pages/refund-automatic"),
							},
							{
								path: "/orders/:oid/manual-refund",
								lazy: () => import("src/pages/refund-manual"),
							},
						],
					},
					{
						path: "/success/:oid/:uid",
						lazy: () => import("src/pages/payment-success"),
					},
					{
						path: "/fail/:oid/:uid",
						lazy: () => import("src/pages/payment-failed"),
					},
					{
						path: "/refund-policy",
						lazy: () => import("src/pages/refund-policy"),
					},
				],
			},
		],
	},
	{
		path: "*",
		lazy: () => import("src/pages/_not-found"),
	},
]);
