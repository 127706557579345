import { Space } from "@alfalab/core-components/space";
import { Button } from "@alfalab/core-components/button";
import { Typography } from "@alfalab/core-components/typography";
import cx from "classnames";

import { HorizontalSelector } from "@/shared/ui/horizontal-selector";

import styles from "./Page.module.css";

export type PageAction = {
	view?: "primary" | "secondary";
	label: string;
	disabled?: boolean;
	loading?: boolean;
	callback(): void;
};

type PageEdges = {
	top?: string | number;
	bottom?: string | number;
};

type PageProps = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	header?: React.ReactNode;
	actions?: Array<PageAction>;
	footer?: React.ReactNode;
	actionsDirection?: "horizontal" | "vertical";
	edges?: PageEdges;
};

const Root: React.FC<PageProps> = ({
	children,
	className,
	header,
	actions,
	footer,
	actionsDirection,
	edges,
	...props
}) => {
	return (
		<div className={cx(styles.root, className)} {...props}>
			{header}
			<div
				className={styles.content}
				style={{
					paddingTop: edges?.top,
					paddingBottom: footer ? 24 : edges?.bottom,
				}}
			>
				{children}
			</div>

			{actions && actions.length > 0 && (
				<div className={styles.actions}>
					<Space size="s" direction={actionsDirection} fullWidth>
						{actions.map(
							({
								view = "primary",
								label,
								disabled = false,
								loading = false,
								callback,
							}) => (
								<Button
									key={label}
									block
									view={view}
									disabled={disabled}
									onClick={callback}
									loading={loading}
								>
									{label}
								</Button>
							),
						)}
					</Space>
				</div>
			)}

			{footer && <div className={styles.footer}>{footer}</div>}
		</div>
	);
};

type PageContainerProps = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
};

const PageContainer: React.FC<PageContainerProps> = ({
	children,
	className,
	...props
}) => {
	return (
		<div className={cx(styles.container, className)} {...props}>
			{children}
		</div>
	);
};

const PageSpacer: React.FC = () => {
	return <div className={styles.spacer} />;
};

type PageSectionProps = {
	title: string;
	children: React.ReactNode;
};

const PageSection: React.FC<PageSectionProps> = ({ title, children }) => {
	return (
		<Space size={8} fullWidth>
			<Page.Container>
				<Typography.TitleMobile view="xsmall" tag="h3" weight="bold">
					{title}
				</Typography.TitleMobile>
			</Page.Container>

			<div>{children}</div>
		</Space>
	);
};

type PageSubSectionProps = {
	title: string;
	children: React.ReactNode;
};

const PageSubSection: React.FC<PageSubSectionProps> = ({ title, children }) => {
	return (
		<Space size={16} fullWidth>
			<Page.Container>
				<Typography.TitleMobile view="xsmall" tag="h3" weight="bold">
					{title}
				</Typography.TitleMobile>
			</Page.Container>

			<div>{children}</div>
		</Space>
	);
};

type PageChipsProps = Pick<
	React.ComponentProps<typeof HorizontalSelector>,
	"data" | "selected" | "onSelect"
>;

const PageChips: React.FC<PageChipsProps> = ({ data, selected, onSelect }) => {
	return (
		<HorizontalSelector
			data={data}
			selected={selected}
			onSelect={onSelect}
			className={styles.chipsContainer}
		/>
	);
};

export const Page = Object.assign(Root, {
	Container: PageContainer,
	Spacer: PageSpacer,
	Section: PageSection,
	SubSection: PageSubSection,
	Chips: PageChips,
});
