import { set, get } from "idb-keyval";

export async function getCachedData<T>(
	key: string,
	ttl: number, // ttl должен быть в миллисекундах
): Promise<T | null> {
	try {
		const now = Date.now();
		const lastUpdate = localStorage.getItem(`${key}_last_update`);

		if (lastUpdate && Number(lastUpdate) > now - ttl) {
			return (await get<T>(key)) ?? null;
		}
	} catch (error) {
		console.error(`Failed to get cached data for key "${key}":`, error);
	}

	return null;
}

export async function cacheData<T>(key: string, data: T) {
	try {
		await set(key, data);
		localStorage.setItem(`${key}_last_update`, String(Date.now()));
	} catch (error) {
		console.error(`Failed to cache data for key "${key}":`, error);
	}
}
