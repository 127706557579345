import { createContext, useContext, useRef } from "react";
import { useSearchParams } from "react-router";
import { createStore, useStore } from "zustand";

import type { Kind } from "@/shared/api";

export type CityFilters = {
	menu: number | "all";
	venues: API.CityVenue["id"][];
	genres: Kind["kindId"][];
	date: [Date, Date] | [Date] | null;
	search: string;
};

type FiltersState = CityFilters & {
	setVenues: (venueIds: CityFilters["venues"]) => void;
	setGenres: (genresIds: CityFilters["genres"]) => void;
	setDate: (dates: CityFilters["date"]) => void;
	setFilters: (filters: Partial<CityFilters>) => void;
	resetFilters: (filters?: CityFilters) => void;
	computed: {
		filtersCount: number;
	};
};

const initialFiltersState: CityFilters = {
	menu: "all",
	genres: [],
	venues: [],
	date: null,
	search: "",
};

const createFiltersStore = (initProps?: Partial<CityFilters>) => {
	return createStore<FiltersState>()((set, get) => ({
		...initialFiltersState,
		...initProps,
		setVenues: (venueIds) => set({ venues: venueIds }),
		setGenres: (genresIds) => set({ genres: genresIds }),
		setDate: (dates) => set({ date: dates }),
		setFilters: (filters) => set((state) => ({ ...state, ...filters })),
		resetFilters: (filters) => set(filters ?? initialFiltersState),
		computed: {
			get filtersCount() {
				const { venues, genres, date } = get();

				return (
					(genres.length > 0 ? 1 : 0) +
					(venues.length > 0 ? 1 : 0) +
					(date ? 1 : 0)
				);
			},
		},
	}));
};

type FiltersStore = ReturnType<typeof createFiltersStore>;

export const CityFiltersContext = createContext<FiltersStore | null>(null);

export function useCityFiltersContext<T>(
	selector: (state: FiltersState) => T,
): T {
	const store = useContext(CityFiltersContext);
	if (!store)
		throw new Error("Missing CityFiltersContext.Provider in the tree");

	return useStore(store, selector);
}

type CityFiltersProviderProps = {
	initialState?: Partial<CityFilters>;
	children: React.ReactNode;
};

export const CityFiltersProvider = ({
	initialState,
	children,
}: CityFiltersProviderProps) => {
	const storeRef = useRef<FiltersStore | null>(null);
	const [searchParams] = useSearchParams();

	if (!storeRef.current) {
		const menuParam = searchParams.get("menu");
		const kindParam = searchParams.get("kind");
		const venuesParam = searchParams.get("venue");
		const dateParam = searchParams.get("date");

		const menu = menuParam ? Number(menuParam) : "all";
		const genres = kindParam
			? kindParam.split("_").map((id) => Number(id))
			: [];
		const venues = venuesParam
			? venuesParam.split("_").map((id) => Number(id))
			: [];
		const date = dateParam
			? (dateParam.split("_").map((date) => new Date(date)) as
					| [Date, Date]
					| [Date])
			: null;

		storeRef.current = createFiltersStore({
			...initialState,
			menu,
			genres,
			venues,
			date,
		});
	}

	return (
		<CityFiltersContext.Provider value={storeRef.current}>
			{children}
		</CityFiltersContext.Provider>
	);
};
