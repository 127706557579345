import { useMemo, createContext, useContext } from "react";
import {
	startOfDay,
	isWithinInterval,
	areIntervalsOverlapping,
} from "date-fns";

import { useCityFiltersContext } from "@/shared/lib";
import { formatString } from "@/shared/utils";

type City = Pick<API.CountryCity, "id" | "name">;

type CityActionsContextValue = {
	city: City & {
		where: string;
		parentCityId?: number;
	};
	genres: Array<API.CityGenre>;
	venues: Array<API.CityVenue>;
	menus: Array<API.CityMenu>;
	actions: Array<API.CityActionItem>;
	unfilteredActions: Array<API.CityActionItem>;
	slider: Array<API.CitySliderItem>;
	selections: Array<API.CitySelectionItem>;
	cinemaGenres: Array<API.CityCinemaGenre>;
	cinemaVenues: Array<API.CityCinemaVenue>;
	totalResultsCount: number;
	banner?: {
		action: {
			id: number;
			from: string;
			to: string;
			cityId: number;
			venues: Array<{
				id: number;
			}>;
		};
		cashback: number;
		dateTo: string;
		title: string;
	};
};

const CityActionsContext = createContext<CityActionsContextValue | null>(null);

export function useCityActionsContext() {
	const context = useContext(CityActionsContext);

	if (!context) {
		throw new Error("Missing CityActionsContext.Provider in the tree");
	}

	return context;
}

type CityActionsProviderProps = {
	data: API.GetActionsResult;
	children: React.ReactNode;
};

export const CityActionsProvider: React.FC<CityActionsProviderProps> = ({
	data,
	children,
}) => {
	const menuFilter = useCityFiltersContext((state) => state.menu);
	const genresFilter = useCityFiltersContext((state) => state.genres);
	const venuesFilter = useCityFiltersContext((state) => state.venues);
	const dateFilter = useCityFiltersContext((state) => state.date);

	const actions = data.actions.data.map((action) => ({
		...action,
		name: formatString(action.name),
		venues: action.venues.map((venue) => ({
			...venue,
			name: formatString(venue.name),
		})),
	}));

	const city = useMemo<City & { where: string }>(() => {
		return {
			id: data.cityId,
			name: data.cityName,
			where: data.where,
			parentCityId: data.parentCityId,
		};
	}, [data]);

	const unfilteredActions = useMemo(() => {
		return Object.values(actions).map((action) => ({
			...action,
			venues: Object.values(action.venues),
		}));
	}, [actions]);

	const filteredActions = unfilteredActions.filter((action) => {
		// venues filter
		if (
			venuesFilter.length > 0 &&
			!venuesFilter.some((venueId) => {
				return action.venues.map(({ id }) => id).includes(Number(venueId));
			})
		) {
			return false;
		}

		// menu filter
		if (menuFilter !== "all" && !action.menu.includes(menuFilter)) {
			return false;
		}

		// genres filter
		if (
			genresFilter.length > 0 &&
			!genresFilter.some((genreId) => {
				return action.genres.map(({ id }) => id).includes(genreId);
			})
		) {
			return false;
		}

		// date filter
		if (dateFilter) {
			const [dateFrom, dateTo] = dateFilter;

			const actionInterval = {
				start: new Date(action.from),
				end: new Date(action.to),
			};

			if (dateFrom && dateTo) {
				const filterInterval = {
					start: startOfDay(dateFrom),
					end: startOfDay(dateTo),
				};

				return areIntervalsOverlapping(actionInterval, filterInterval);
			}

			return isWithinInterval(startOfDay(dateFrom), actionInterval);
		}

		return true;
	});

	return (
		<CityActionsContext.Provider
			value={{
				city,
				genres: data.genres,
				venues: Object.values(data.venues).map(({ name, ...venue }) => ({
					...venue,
					name: formatString(name),
				})),
				slider: data.slider,
				menus: data.menu,
				selections: data.selections,
				totalResultsCount: filteredActions.length,
				actions: filteredActions,
				unfilteredActions,
				cinemaGenres: data.movieGenres,
				cinemaVenues: data.cinemaVenues,
				banner: data.banner,
			}}
		>
			{children}
		</CityActionsContext.Provider>
	);
};
