import type { APIResponseSuccess } from "@/shared/api/client";
import { API_ENDPOINT } from "@/shared/constants";
import { AuthorizationError, GuidError } from "@/shared/error";

const REFRESH_TOKEN_KEY = "refreshToken";
const GUID_KEY = "_guid";

type AlfaAuthResult = {
	cityId: number;
	trackingId: string;
	role: "user" | "test" | "supervisor";
	auth: {
		token: string;
		tokenExpiredAt: string;
		refreshToken: string;
		refreshTokenExpiredAt: string;
	};
};

type AlfaAuthRefreshResult = {
	token: string;
	tokenExpiredAt: string;
	refreshToken: string;
	refreshTokenExpiredAt: string;
};

export class AuthService {
	private accessToken: string | null = null;

	private getAccessToken = () => this.accessToken;
	private getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
	private setAccessToken = (token: string) => {
		this.accessToken = token;
	};
	private setRefreshToken = (token: string) =>
		localStorage.setItem(REFRESH_TOKEN_KEY, token);

	private setTrackingId = (trackingId: string) => {
		localStorage.setItem("trackingId", trackingId);
		window._trackingId = trackingId;
	};
	private setRole(role: string) {
		localStorage.setItem("role", role);
	}

	private setGuid(guid: string) {
		localStorage.setItem(GUID_KEY, guid);
	}
	private getPrevGuid() {
		return localStorage.getItem(GUID_KEY);
	}

	async auth(args: { guid: string }) {
		const prevGuid = this.getPrevGuid();

		if (args.guid === prevGuid) {
			throw new GuidError("GUID invalid");
		}

		const data = await this.request<AlfaAuthResult>("/alfa_auth", {
			body: JSON.stringify({ guid: args.guid }),
		}).catch(() => {
			throw new AuthorizationError("Failed to auth");
		});

		this.setGuid(args.guid);

		this.setAccessToken(data.auth.token);
		this.setRefreshToken(data.auth.refreshToken);
		this.setTrackingId(data.trackingId);
		this.setRole(data.role);

		return data;
	}

	async refreshToken() {
		const refreshToken = this.getRefreshToken();
		if (!refreshToken) throw new AuthorizationError("No refresh token");

		const data = await this.request<AlfaAuthRefreshResult>("/token/refresh", {
			body: JSON.stringify({ refreshToken }),
		}).catch(() => {
			throw new AuthorizationError("Failed to refresh token");
		});

		this.setAccessToken(data.token);
		this.setRefreshToken(data.refreshToken);

		return data.token;
	}

	getAuthState() {
		const accessToken = this.getAccessToken();
		const refreshToken = this.getRefreshToken();

		return {
			accessToken,
			refreshToken,
		};
	}

	private request<TData>(url: string, options: RequestInit = {}) {
		return fetch(`${API_ENDPOINT}/json${url}`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			...options,
		})
			.then((res) => res.json() as Promise<APIResponseSuccess<TData>>)
			.then((res) => res.decode);
	}
}

export const authService = new AuthService();
