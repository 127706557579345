import { apiClient } from "../client";

export type Kind = {
	kindId: number;
	kindName: string;
};

export type ActionEvent = {
	actionEventAlterText: string;
	categories: boolean;
	eventId: number;
	placements: boolean;
	sellEndTime: string;
	eventTime: string;
	fanIdRequired: boolean;
	cashback: number;
};

export type ActionExt = {
	actionId: number;
	actionName: string;
	address: string;
	age: string;
	description?: string;
	from: string;
	to: string;
	fullActionName: string;
	geoLat: string;
	geoLon: string;
	kdp: boolean;
	poster: string;
	venueId: number;
	venueName: string;
	events: Array<ActionEvent>;
	venues: Array<{
		venueId: number;
		venueName: string;
	}>;
	price: {
		min: number;
		max: number;
	};
	cashback: {
		min: number;
		max: number;
		value: number;
	};
	when?: string;
	posterFormat: "default" | "1280x392" | "1242x800";
	soldout_events: [];
	new_orders: {
		orders: number;
		time: number;
		reservationExpiredAt: string | null;
	};
	type: "cinema" | "common";
};

export type GetActionResult = ActionExt;

export function getAction(args: {
	actionId: string;
	venueId: string;
	cityId: string;
}) {
	return apiClient.post<GetActionResult>(
		"/v2/action",
		{
			aid: args.actionId,
			cid: args.cityId,
			vid: args.venueId,
		},
		{ auth: true },
	);
}

type GetCinemaActionResult = {
	title: string;
	originalTitle: string;
	poster: string;
	description: string;
	year: number;
	directors: Array<{
		id: string;
		title: string;
	}>;
	actors: Array<{
		id: string;
		title: string;
	}>;
	screenshots: string[];
	countries: Array<{
		id: string;
		title: string;
	}>;
	duration: number | null;
	genres: Array<{
		id: string;
		title: string;
	}>;
	trailer: string | null;
	trailerPreview: string | null;
	cashback: number;
};

export function getCinemaAction(args: {
	cityId: string;
	actionId: string;
}): Promise<GetCinemaActionResult> {
	return apiClient.post<GetCinemaActionResult>(
		"/v2/cinema/action",
		{
			aid: args.actionId,
			cid: args.cityId,
		},
		{
			auth: true,
		},
	);
}

export type CinemaActionGenre = GetCinemaActionResult["genres"][number];

export type CinemaActionFormat = {
	id: number;
	title: string;
};

export type CinemaActionVenue = {
	id: string;
	name: string;
	address: string;
	cityId: string;
	events: Array<{
		id: string;
		date: string;
		minPrice: number | null;
		formats: Array<CinemaActionFormat>;
	}>;
};

export type GetCinemaActionEventsResult = {
	venues: Array<CinemaActionVenue>;
	formats: Array<CinemaActionFormat>;
};

export function getCinemaActionEvents(args: {
	cityId: string;
	actionId: string;
}) {
	return apiClient.post<GetCinemaActionEventsResult>(
		"/v2/cinema/events",
		{
			cid: args.cityId,
			aid: args.actionId,
		},
		{ auth: true },
	);
}
