import { apiClient } from "../client";

export async function auth(args: { email: string }) {
	return apiClient.post<"OK" | false>("/auth", args, {
		auth: true,
	});
}

export async function getAlfaIDAuthURL(args: {
	nextRoute: string;
}) {
	return apiClient.post<{
		url: string;
	}>(
		"/alfa_id_auth_url",
		{
			next_route: args.nextRoute,
		},
		{ auth: true },
	);
}

export function alfaIDAuth(args: {
	code: string | null;
	state: string;
	declined: boolean;
}) {
	return apiClient.post<{
		nextRoute: string;
		profile: {
			name: string;
			phone: string;
			email: string;
			alfaIdConnected: false;
			alfaAuthDeclined: false;
		};
	}>("/alfa_id_auth_code", args, { auth: true });
}
