import { createContext, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";

import type { Theme } from "./types";

type AppContextValue = {
	appId: string;
	theme: Theme;
};

const AppContext = createContext<AppContextValue | null>(null);

export function useAppContext() {
	const context = useContext(AppContext);

	if (!context) {
		throw new Error("Missing AppContext.Provider in the tree");
	}

	return context;
}

type AppProviderProps = {
	children: React.ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	const searchParams = new URLSearchParams(location.search);

	useEffect(() => {
		const referrer = document.referrer;
		const hasReferrer =
			referrer.length > 1 && new URL(referrer).origin !== location.origin;

		Sentry.addBreadcrumb({
			category: "navigation",
			message: hasReferrer
				? `Initial route ${location.href} from ${referrer}`
				: `Initial route ${location.href}`,
			level: "info",
		});
	}, []);

	// AppID

	const appIdParam = searchParams.get("appId");
	const iosAppIdParam = searchParams.get("iosAppId");

	let appId = "alfabank";

	if (iosAppIdParam !== null) {
		appId = iosAppIdParam;
		localStorage.setItem("app_id", iosAppIdParam);
	} else if (appIdParam !== null) {
		appId = appIdParam;
		localStorage.setItem("app_id", appIdParam);
	} else {
		const cachedAppId = localStorage.getItem("app_id");

		if (cachedAppId) {
			appId = cachedAppId;
		}
	}

	// Theme

	let theme: Theme = "light";

	const alphaThemeParam = searchParams.get("theme");

	if (alphaThemeParam) {
		theme = alphaThemeParam as Theme;
		localStorage.setItem("theme", theme);
	} else {
		const storedTheme = localStorage.getItem("theme") as Theme;
		if (storedTheme) theme = storedTheme;
	}

	document.body.setAttribute("data-theme", theme);

	return (
		<AppContext.Provider
			value={{
				appId,
				theme,
			}}
		>
			{theme === "dark" && <link href="/static/dark.css" rel="stylesheet" />}
			{children}
		</AppContext.Provider>
	);
};
