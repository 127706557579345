import { RouterProvider } from "react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AppProvider } from "@/shared/lib";
import { queryClient } from "@/shared/query-client";

import { router } from "./router";

export const Providers: React.FC = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<AppProvider>
				<RouterProvider router={router} />
			</AppProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
};
