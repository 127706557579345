import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";

import { Page } from "@/shared/ui/layout";

import styles from "./InfoPage.module.css";

export type InfoPageProps = {
	icon: React.ReactNode;
	tone?: "critical" | "positive" | "info";
	title: string;
	message?: string;
	actions?: Array<{
		view?: "primary" | "secondary";
		label: string;
		callback(): void;
	}>;
};

export const InfoPage: React.FC<InfoPageProps> = ({
	icon,
	tone = "info",
	title,
	message,
	actions,
}) => {
	return (
		<Page>
			<Page.Spacer />

			<Page.Container style={{ justifyContent: "center" }}>
				<Space size="l" align="center">
					<SuperEllipse
						size={80}
						backgroundColor={
							{
								critical: "#F15045",
								positive: "#2FC26E",
								info: undefined,
							}[tone]
						}
					>
						{icon}
					</SuperEllipse>

					<Space align="center" size={"s"}>
						<Typography.TitleMobile tag="h2" view="small" weight="medium">
							{title}
						</Typography.TitleMobile>

						{message && (
							<Typography.Text view="primary-medium" className={styles.message}>
								{message}
							</Typography.Text>
						)}
					</Space>
				</Space>
			</Page.Container>

			<Page.Spacer />

			{actions && (
				<Page.Container>
					<Space size="m" fullWidth>
						{actions.map((action, idx) => (
							<Button
								key={idx.toString()}
								block
								view={action.view || "primary"}
								onClick={action.callback}
							>
								{action.label}
							</Button>
						))}
					</Space>
				</Page.Container>
			)}
		</Page>
	);
};
