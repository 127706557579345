import { Minute } from "@/shared/utils";
import { ENV } from "@/shared/constants";

import { apiClient } from "../client";
import { getCachedData, cacheData } from "../cache";

import type { Paginated } from "./types";

type GetAllCitiesResult = Array<{
	countryId: number;
	countryName: string;
	cities: Array<{
		id: number;
		name: string;
		region: string;
		towns?: Array<{
			id: number;
			name: string;
		}>;
	}>;
}>;

export async function getAllCities(): Promise<GetAllCitiesResult> {
	return apiClient.post<GetAllCitiesResult>(
		"/v2/city/list",
		{},
		{ auth: true },
	);
}

export type CountryCity = GetAllCitiesResult[number]["cities"][number];

export async function setCity(args: { cityId: string | number }) {
	return apiClient.post<"Ok" | false>(
		"/set_city",
		{
			cid: String(args.cityId),
		},
		{ auth: true },
	);
}

export type GetActionsResult = {
	banner?: {
		action: {
			id: number;
			from: string;
			to: string;
			cityId: number;
			venues: Array<{
				id: number;
			}>;
		};
		cashback: number;
		dateTo: string;
		title: string;
	};
	actions: Paginated<{
		kindId: number;
		id: number;
		name: string;
		description: any;
		poster: string;
		duration: number;
		price: {
			min: number;
			max: number;
		};
		cashback: {
			value: number;
			min: number;
			max: number;
		};
		from: string;
		to: string;
		time: string;
		age: string;
		fanIdRequired: boolean;
		kdp: boolean;
		venues: Array<{
			address: string;
			id: number;
			name: string;
		}>;
		genres: Array<{
			id: number;
			name: string;
		}>;
		movieGenres: Array<{
			id: number;
			name: string;
			rank: string;
		}>;
		menu: number[];
		rank: number;
		type: "common" | "cinema";
		cityId: number;
	}>;
	menu: Array<{
		id: number;
		name: string;
	}>;
	kinds: Array<{
		id: number;
		name: string;
	}>;
	genres: Array<{
		id: number;
		name: string;
		address: string;
	}>;
	venues: Array<{
		id: number;
		name: string;
	}>;
	selections: Array<{
		id: number;
		name: string;
		rank: number;
		short_name: string;
		events: Array<{
			actionId: string;
			age: string;
			cityId: string;
			countryId: string;
			dates: string;
			cashback: {
				value: number;
				min: number;
				max: number;
			};
			eventId: number;
			id: number;
			name: string;
			popularity: number;
			poster: string;
			price: {
				min: number;
				max?: number;
			};
			type: string;
			venueId: string;
		}>;
		test_events: [];
	}>;
	slider: Array<{
		actionId: string;
		age: string;
		button: string;
		cashback: {
			value: number;
			min: number;
			max: number;
		};
		cityId: string;
		countryId: string;
		dates: string;
		eventId: number;
		id: number;
		picture: string;
		place: string;
		price: {
			min: number;
			max?: number;
		};
		rank: number;
		type: string;
		venueId: string;
		video: string | null;
		title: string;
		description?: string;
		url?: string;
	}>;
	movieGenres: Array<{
		id: number;
		name: string;
		rank: string;
	}>;
	cinemaVenues: Array<{
		id: number;
		name: string;
		rank: string;
		address: string;
		eventsCount: Record<string, number>;
		location: {
			latitude: number;
			longitude: number;
		};
		metroStations: null;
	}>;
	countryId: number;
	countryName: string;
	cityId: number;
	cityName: string;
	where: string;
	parentCityId: number;
};

export async function getActions(args: {
	cityId: string | number;
}): Promise<GetActionsResult> {
	const isProd = ENV === "prod";

	const cachedData = await getCachedData<GetActionsResult>(
		`getAllActionsByCity-${args.cityId}`,
		Minute * 6,
	);

	if (cachedData && isProd) {
		return cachedData;
	}

	const result = await apiClient
		.post<GetActionsResult>(
			"/v2/action/list",
			{
				cid: String(args.cityId),
			},
			{ auth: true },
		)
		.then((data) => {
			if (isProd) {
				const excludedNames = ["черная пятница!", "чёрная пятница!"];

				const selections = data.selections.filter(
					(selection) => !excludedNames.includes(selection.name.toLowerCase()),
				);

				return {
					...data,
					selections,
				};
			}

			return data;
		});

	await cacheData(`getAllActionsByCity-${args.cityId}`, result);

	return result;
}

export type CityBanner = GetActionsResult["banner"];
export type CityActionItem = GetActionsResult["actions"]["data"][number];
export type CitySliderItem = GetActionsResult["slider"][number];
export type CitySelectionItem = GetActionsResult["selections"][number];
export type CityVenue = GetActionsResult["venues"][number];
export type CityGenre = GetActionsResult["genres"][number];
export type CityMenu = GetActionsResult["menu"][number];
export type CityCinemaVenue = GetActionsResult["cinemaVenues"][number];
export type CityCinemaGenre = GetActionsResult["movieGenres"][number];
