import { useEffect } from "react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router";
import * as Sentry from "@sentry/react";

import pkg from "~/package.json";

import { ENV, SENTRY_DSN } from "./constants";

export type ErrorType = "warning" | "message";

export class APIError extends Error {
	code: string;
	message: string;
	advice: string;
	system: string;
	type: "warning" | "message";
	error: number | string;

	constructor(config: {
		code: string;
		message: string;
		advice: string;
		system: string;
		type?: ErrorType;
		error: number | string;
	}) {
		super();

		this.code = config.code;
		this.advice = config.advice;
		this.system = config.system;
		this.message = config.message || "Произошла ошибка";
		this.type = config.type || "warning";
		this.error = config.error;
	}

	toJSON() {
		return JSON.stringify({
			code: this.code,
			advice: this.advice,
			system: this.system,
			message: this.message,
			type: this.type,
			error: this.error,
		});
	}
}

export class NetworkError extends Error {
	constructor(message: string) {
		super();
		this.message = message;
	}
}

export class AuthorizationError extends Error {
	constructor(message: string) {
		super();
		this.message = message;
	}
}

export class GuidError extends Error {
	constructor(message: string) {
		super();
		this.message = message;
	}
}

export class EmptyCartError extends Error {
	constructor(message: string) {
		super();
		this.message = message;
	}
}

export class CartTimeoutError extends Error {
	constructor() {
		super("Cart timeout");
	}
}

type ValidationErrorPayload<TPath = string> = {
	code: string;
	errors: Array<{ path: TPath; message: string }>;
	message: string;
};

export class ValidationError<TPath> extends Error {
	code: string;
	errors: Array<{ path: TPath; message: string }>;
	message: string;

	constructor(args: ValidationErrorPayload<TPath>) {
		super("Validation error");

		this.code = args.code;
		this.errors = args.errors;
		this.message = args.message;
	}
}

const err = new ValidationError<"number">({
	code: "invalid_number",
	errors: [{ path: "number", message: "Invalid number" }],
	message: "Invalid number",
});

if (err instanceof ValidationError) {
	console.log(err.message);
}

export function initErrorTracker() {
	if (ENV === "prod") {
		Sentry.init({
			dsn: SENTRY_DSN,
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				}),
			],
			beforeBreadcrumb(breadcrumb) {
				const trackingId = localStorage.getItem("trackingId");

				if (breadcrumb.category === "fetch" && breadcrumb.data) {
					const url = breadcrumb.data.url;

					if (url.includes("mc.yandex.ru")) {
						return null;
					}

					if (trackingId) {
						breadcrumb.data.trackingId = trackingId;
					}
				}

				if (
					breadcrumb.category === "navigation" &&
					breadcrumb.data &&
					trackingId
				) {
					breadcrumb.data.trackingId = trackingId;
				}

				if (breadcrumb.category === "console") {
					return null;
				}

				return breadcrumb;
			},
			environment: ENV === "prod" ? "production" : "staging",
			release: `${pkg.name}@${process.env.PUBLIC_VERSION}`,
		});
	}
}

const IGNORED_ERRORS = ["unable_load_seats"];

export function captureException(error: Error) {
	if (error instanceof NetworkError) {
		return;
	}

	if (error instanceof APIError) {
		const errorPayload = error.toJSON();

		if (
			error.type === "warning" &&
			!IGNORED_ERRORS.includes(String(error.error))
		) {
			const errorToSentry = new Error(
				`fetch error: ${JSON.stringify(errorPayload)}`,
			);

			errorToSentry.name = error.system;

			Sentry.captureException(errorToSentry, (scope) => {
				scope.setTransactionName(location.pathname);
				scope.setTag("trackingId", localStorage.getItem("trackingId"));
				return scope;
			});
		}

		return;
	}

	Sentry.captureException(error);
}
