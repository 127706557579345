import { ENV } from "@/shared/constants";
import { captureException, NetworkError } from "@/shared/error";

type LogLevel = "debug" | "info" | "warn" | "error";

const LOG_LEVEL: LogLevel = ENV === "prod" ? "error" : "debug";

const levels: Record<LogLevel, number> = {
	debug: 0,
	info: 1,
	warn: 2,
	error: 3,
};

function shouldLog(level: LogLevel) {
	return levels[level] >= levels[LOG_LEVEL];
}

function debug(message: string, ...args: unknown[]) {
	if (shouldLog("debug")) {
		console.debug(message, ...args);
	}
}

function info(message: string, ...args: unknown[]) {
	if (shouldLog("info")) {
		console.info(message, ...args);
	}
}

function warn(message: string, ...args: unknown[]) {
	if (shouldLog("warn")) {
		console.warn(message, ...args);
	}
}

function error(message: string, error: Error) {
	if (shouldLog("error")) {
		console.error(message, error.stack || error.message);
	}

	if (ENV === "prod" && !(error instanceof NetworkError)) {
		captureException(error);
	}
}

export const logger = {
	debug,
	info,
	warn,
	error,
};
