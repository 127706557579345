import { apiClient } from "../client";

export type EmailConfirmationResult = {
	message: string;
	advice: string;
	nextEmailConfirmationAvailableAt: string | null;
};

export async function emailConfirmation(args: { token: string }) {
	return apiClient.post<EmailConfirmationResult>("/email_confirmation", args);
}

export function requestEmailConfirmation() {
	return apiClient.post<EmailConfirmationResult>(
		"/request_email_confirmation",
		{},
		{ auth: true },
	);
}
