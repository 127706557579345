import { apiClient } from "../client";

export type GetSelection = {
	countryId: number;
	countryName: string;
	cityId: number;
	cityName: string;
	parentCityId: number;
	selection: {
		id: number;
		name: string;
		short_name: string;
		rank: number;
		events: Array<{
			actionId: string;
			age: string;
			cashback: {
				max: number;
				min: number;
				value: number;
			};
			cityId: string;
			countryId: string;
			dates: string;
			eventId: number;
			id: number;
			name: string;
			popularity: number;
			poster: string;
			price: { min: number; max: number };
			type: string;
			venueId: string;
		}>;
	};
	new_orders: {
		orders: number;
		time: number;
	};
};

export function getSelection(args: { cid: number | string; sid: number }) {
	return apiClient.post<GetSelection>("/v2/selection", args, {
		auth: true,
	});
}

export type GetSelectionResult = Awaited<ReturnType<typeof getSelection>>;

export type Selection = GetSelectionResult;
export type SelectionEvent = GetSelectionResult["selection"]["events"][number];
