import { apiClient } from "../client";

export async function refundOrder(args: {
	oid: string;
	tids: Array<{ tid: string }>;
	name?: string;
	phone?: string;
	reason: string;
	comment?: string;
	email?: string;
	emptyAmount?: boolean;
}) {
	return apiClient.post<true>("/v2/order/refund/manual", args, {
		auth: true,
	});
}

export type GetRefundAmountResult = {
	refundingPercent: number;
	refundingSum: number;
	sum: number;
	serviceFee: number;
	ticketList: Array<{
		ticketId: number;
		price: number;
		serviceCharge: number;
		refundingPercent: number;
		refundingSum: number;
		categoryName?: string;
		row?: string;
		sector?: string;
		number?: string;
	}>;
	user: {
		name: string;
		mail: string;
		phone: string;
	};
};

export async function getRefundAmount(args: {
	oid: string;
	tids: Array<{ tid: string }>;
}) {
	return apiClient.post<GetRefundAmountResult>(
		"/v2/order/refund/refund_amount",
		args,
		{
			auth: true,
		},
	);
}

export function automaticRefundOrder(args: {
	oid: string;
	tids: Array<{ tid: string }>;
}) {
	return apiClient.post<true>("/v2/order/refund/automatic", args, {
		auth: true,
	});
}
