import { Platform, detectPlatform } from "./detectPlatform";

export function downloadPdf(pdfUrl: string, options: { appId: string }) {
	const platform = detectPlatform();

	if (platform === Platform.IOS) {
		location.replace(
			`${
				options.appId
			}:///dashboard/pdf_viewer?type=pdfFile&url=${encodeURIComponent(pdfUrl)}`,
		);
	} else if (platform === Platform.ANDROID) {
		location.replace(
			`alfabank://pdf_viewer?url=${encodeURIComponent(pdfUrl)}&file=f.pdf`,
		);
	} else {
		window.open(pdfUrl);
	}
}
