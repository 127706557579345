import { BUILD_DATE } from "@/shared/constants";

export function getBuildDate() {
	const date = new Date(BUILD_DATE);

	return date.toLocaleDateString("ru", {
		day: "2-digit",
		month: "2-digit",
		year: "2-digit",
	});
}
