import { useEffect } from "react";
import { useRouteError } from "react-router";
import { InternetOffMIcon } from "@alfalab/icons-glyph/InternetOffMIcon";

import type { PageAction } from "@/shared/ui/layout";
import { APIError, NetworkError } from "@/shared/error";
import { Platform, detectPlatform } from "@/shared/utils";
import { logger } from "@/shared/logger";
import { AB_WEB_HOME_URI, AB_VIEW_HOME_URI } from "@/shared/constants";

import { ErrorPage } from "./ErrorPage";

export const ErrorBoundary: React.FC = () => {
	const error = useRouteError() as Error;

	useEffect(() => {
		logger.error(error.message, error);
	}, []);

	const isWeb = detectPlatform() === Platform.WEB;
	const homeUrl = isWeb ? AB_WEB_HOME_URI : AB_VIEW_HOME_URI;

	let title: string | undefined;
	let message = "На нашей стороне, но мы уже чиним";
	let details = error.message;

	const actions: PageAction[] = [];

	if (error instanceof NetworkError) {
		actions.push({
			label: "Попробовать ещё раз",
			callback: () => window.location.reload(),
		});

		return (
			<ErrorPage
				icon={<InternetOffMIcon color="black" />}
				title="Потеряли связь с вами"
				message="Проверьте подключение к интернету и попробуйте снова"
				actions={actions}
			/>
		);
	}

	if (error instanceof APIError) {
		details = error.system;
	}

	if (isWeb) {
		title = "Не получилось загрузить";
		message = "Вернитесь в Альфа-Онлайн и попробуйте ещё раз";
		actions.push({
			label: "Перейти в Альфа-Онлайн",
			callback: () => window.location.replace(homeUrl),
		});
	} else {
		actions.push({
			label: "Вернуться на главный экран",
			callback: () => window.location.replace(homeUrl),
		});
	}

	return (
		<ErrorPage
			title={title}
			message={message}
			actions={actions}
			details={details}
		/>
	);
};
