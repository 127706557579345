export const MAX_CITIES_HISTORY_ITEMS = 5;
export const MAX_PLACES_HISTORY_ITEMS = 5;
export const MAX_SEARCH_RESULTS = 50;

export const VERSION = process.env.PUBLIC_VERSION || "dev";
export const BUILD_DATE = process.env.BUILD_DATE as string;

export const AB_WEB_HOME_URI =
	"https://web.alfabank.ru/partner-offers/afisha-transfer";

export const AB_VIEW_HOME_URI =
	"alfabank://webFeature?type=recommendation&url=https%3A%2F%2Fweb.alfabank.ru%2Fpartner-offers%2Fafisha-transfer";

export const ENV =
	typeof process !== "undefined"
		? (process.env.PUBLIC_DEPLOY_ENV as "devstg" | "prod")
		: "prod";

export const API_ENDPOINT = {
	devstg: "https://api-alfa-test.kassir.ru",
	prod: "https://api-alfa.kassir.ru",
}[ENV];

export function getRuntimeEnv() {
	if (location.host === "www.alfa-dev1.kassirdev.ru") {
		return "dev1";
	}

	if (location.host === "www.alfa-dev2.kassirdev.ru") {
		return "dev2";
	}

	if (location.host === "alfa-test.kassir.ru") {
		return "stg";
	}

	if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
		return "dev";
	}

	return "prod";
}

export function getAPIEndpoint() {
	const runtimeEnv = getRuntimeEnv();

	if (runtimeEnv === "dev1") {
		return "https://api.alfa-dev1.kassirdev.ru";
	}

	if (runtimeEnv === "dev2") {
		return "https://api.alfa-dev2.kassirdev.ru";
	}

	if (runtimeEnv === "stg") {
		return "https://api-alfa-test.kassir.ru";
	}

	if (runtimeEnv === "dev") {
		return "https://api-alfa-test.kassir.ru";
	}

	return "https://api-alfa.kassir.ru";
}

export const SENTRY_ORG = "mullerstd";

export const SENTRY_DSN =
	"https://9e6bd9aeba845ea63e5dc1881ec15f58@o249182.ingest.us.sentry.io/4507864255954944";

// export const SENTRY_DSN =
// 	"https://10e6640680d24cfb819a183661fb4065@o4508734483529728.ingest.de.sentry.io/4508736645431376";

export const AM_ENDPOINT =
	ENV === "prod"
		? "metrics.alfabank.ru/metrica/retail"
		: "testjmb.alfabank.ru/metrica/retail";
export const AM_APP_ID = `kassir_ru_${ENV === "prod" ? "int" : "dev"}`;

export const JWT_ENABLED = true;

export const YANDEX_MAPS_API_KEY = "79791d73-137f-4cf0-8ed7-a8e0fc12d3ca";
