import { apiClient } from "../client";

export async function getDocumentTypes() {
	return apiClient.get<{
		documentTypes: Array<{
			id: string;
			name: string;
			fields: {
				series: {
					required: boolean;
					format: "number" | "text";
					regex: string;
					min: number;
					max: number;
				} | null;
				number: {
					required: boolean;
					format: "number" | "text";
					regex: string;
					min: number;
					max: number;
				};
			};
		}>;
	}>("/document_type/list");
}

export type DocumentType = Awaited<
	ReturnType<typeof getDocumentTypes>
>["documentTypes"][0];

export async function getVisitors() {
	return apiClient.post<{
		visitors: Array<{
			id: string;
			name: string;
			surname: string;
			middleName?: string;
			type: string;
			number: string;
			series?: string;
		}>;
	}>("/visitor/list", {}, { auth: true });
}

export type Visitor = Awaited<
	ReturnType<typeof getVisitors>
>["visitors"][number];

export async function createVisitor(args: {
	data: Pick<
		Visitor,
		"name" | "surname" | "middleName" | "type" | "number" | "series"
	>;
}) {
	return apiClient.post<Visitor>("/visitor", args.data, { auth: true });
}

export async function updateVisitor(args: {
	visitorId: string;
	data: Pick<
		Visitor,
		"name" | "surname" | "middleName" | "type" | "number" | "series"
	>;
}) {
	return apiClient.put<Visitor>(`/visitor/${args.visitorId}`, args.data, {
		auth: true,
	});
}
