import { EmptyCartError } from "@/shared/error";

import { apiClient } from "../client";

export type GetCartResult = {
	currency: string;
	time: number;
	actionEventList: Array<{
		cityId: number;
		cityName: string;
		venueId: number;
		venueName: string;
		actionId: number;
		actionName: string;
		eventId: number;
		eventTime: string;
		serviceCharge: number;
		fullNameRequired: boolean;
		phoneRequired: boolean;
		fanIdRequired: boolean;
		visitorDocRequired: boolean;
		seatList: Array<{
			seatId: number;
			price: number;
			categoryPriceId: number;
			categoryPriceName: string;
			tariffPlanId: string | null;
			tariffPlanName: string | null;
			nominal: number;
			serviceCharge: number;
			discount: number;
			sector: string;
			row: string;
			number: string;
			visitor: API.VisitorData | null;
		}>;
	}>;
	totalSum: number;
	totalServiceCharge: number;
	cashback: number;
	formUrl: string;
	userInfo: {
		mail: string;
		name: string;
		phone: string;
	};
	adjustments: {
		cashback_amount: boolean;
		title_trainer?: string;
		trainer: string;
		info: string;
	};
	reservationExpiredAt: string;
};

export async function getCart(args: { oid?: string }) {
	const cart = await apiClient.post<GetCartResult>("/v2/cart", args, {
		auth: true,
	});

	if (cart.actionEventList.length === 0) {
		throw new EmptyCartError("Корзина пуста");
	}

	const actionEvent = cart.actionEventList[0];

	return {
		...cart,
		event: actionEvent,
	};
}

export type Cart = Awaited<ReturnType<typeof getCart>>;
export type CartEvent = Cart["actionEventList"][number];
export type CartSeat = CartEvent["seatList"][number];
