import { NetworkError } from "@/shared/error";

export function patchFetch() {
	const { fetch: origFetch, Request } = globalThis;

	globalThis.fetch = async function (
		input: RequestInfo | URL,
		init?: RequestInit,
	): Promise<Response> {
		const request = new Request(input, init);
		const stack = new Error().stack;

		try {
			return await origFetch.call(this, request);
		} catch (err) {
			if (err instanceof Error) {
				err.stack = stack;

				if (err.name === "AbortError") {
					throw err;
				}

				throw new NetworkError(err.message);
			}

			throw err;
		}
	};
}
