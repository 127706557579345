import { apiClient } from "../client";

export enum OrderStatus {
	NEW = 0,
	PROCESSING = 1,
	PAID = 2,
	CANCELLING = -1,
	CANCELLED = -2,
	REFUNDED = 3,
}

export type CreateOrderResult = {
	orderId: number;
	formUrl: string;
	statusExtStr: string;
	statusExtInt: OrderStatus;
};

export type CreateOrderArgs = {
	sum: string;
	currency: string;
	phone?: string;
	name?: string;
	visitors?: Record<string, string>;
};

export async function createOrder(args: CreateOrderArgs) {
	return apiClient.post<CreateOrderResult>("/v2/order/create", args, {
		auth: true,
	});
}

export type Order = {
	actionId: string;
	actionName: string;
	actual: boolean;
	age: string;
	cityId: string;
	cityName: string;
	created: string;
	eventTime: string | null;
	currency: string;
	discount: number;
	eventId: string;
	orderId: string;
	orderIdAlt: string;
	quantity: number;
	serviceCharge: number;
	sum: number;
	userMessage: string;
	venueId: string;
	venueName: string;
	ticketList: Array<{
		ticketId: number;
		date: string;
		actionName: string;
		venueName: string;
		categoryName: string;
		price: number;
		event: number;
		sector?: string;
		row?: string;
		number?: string;
	}>;
	time?: number;
	statusExtStr:
		| "NEW"
		| "PROCESSING"
		| "PAID"
		| "CANCELLING"
		| "CANCELLED"
		| "REFUNDED";
	statusExtInt: OrderStatus;
	formUrl: string;
	localInfo?: {
		event: number;
		cityId: number;
		cityName: string;
		venueId: number;
		venueName: string;
		actionId: number;
		actionName: string;
		age: string;
	};
	reservationExpiredAt: string | null;
};

export type GetOrdersResult = {
	orderList: Array<Order>;
};

export async function getOrders() {
	return apiClient.post<GetOrdersResult>("/v2/order/list", {}, { auth: true });
}

export function getNewOrders() {
	return apiClient.post<{
		orders: number;
		reservationExpiredAt: string | null;
	}>("/v2/order/pending", {}, { auth: true });
}
