import { Fragment, useEffect } from "react";

import { setPageSettings } from "@/shared/utils";
import { trackScreenView } from "@/shared/metrica";

type ScreenProps = {
	pageId?: number;
	amName?: string;
	title?: string;
	children: React.ReactNode;
};

export const Screen: React.FC<ScreenProps> = ({
	title = "Афиша",
	pageId,
	amName,
	children,
}) => {
	setPageSettings({
		pageId,
		pageTitle: title,
	});

	useEffect(() => {
		if (amName) {
			// trackScreenView(amName);
		}
	}, []);

	return (
		<Fragment>
			<title>{title}</title>

			{children}
		</Fragment>
	);
};
