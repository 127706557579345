import { getAPIEndpoint } from "@/shared/constants";
import { APIError } from "@/shared/error";
import { authService } from "@/shared/lib";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type RequestOptions = RequestInit & {
	params?: Record<string, string | number>;
	auth?: boolean;
};

export type APIResponseSuccess<TDecode> = {
	type: "success";
	decode: TDecode;
	error: false;
	message: false;
	advice: false;
	system: false;
	api_key: string;
	mode: number;
};

export type ValidationErrorPayload<TPath = string> = {
	code: string;
	errors: Array<{ path: TPath; message: string }>;
	message: string;
};

export type APIResponseError = {
	type: "warning" | "message";
	decode: false;
	error: number;
	message: string;
	advice: string;
	system: string;
	api_key: false;
	mode: number;
	details: ValidationErrorPayload;
};

export type APIResponse<TDecode> =
	| APIResponseSuccess<TDecode>
	| APIResponseError;

type AlfaAuthRefreshResult = {
	token: string;
	tokenExpiredAt: string;
	refreshToken: string;
	refreshTokenExpiredAt: string;
};

class HttpClient {
	private baseURL: string;
	private isRefreshing = false;
	private refreshSubscribers: ((token: string) => void)[] = [];

	constructor(baseURL: string) {
		this.baseURL = baseURL;
	}

	private subscribeTokenRefresh(callback: (token: string) => void) {
		this.refreshSubscribers.push(callback);
	}

	private onRefreshed(newToken: string) {
		for (const cb of this.refreshSubscribers) {
			cb(newToken);
		}

		this.refreshSubscribers = [];
	}

	private buildURL(
		url: string,
		params?: Record<string, string | number>,
	): string {
		const query = params
			? `?${new URLSearchParams(params as Record<string, string>).toString()}`
			: "";
		return `${this.baseURL}/json${url}${query}`;
	}

	private async handleUnauthorized<TData>(
		fullURL: string,
		method: HttpMethod,
		options: RequestOptions,
		headers: Headers,
	): Promise<TData> {
		const promise = new Promise<TData>((resolve, reject) => {
			this.subscribeTokenRefresh(async (newToken) => {
				try {
					headers.set("Authorization", `Bearer ${newToken}`);

					const retryResponse = await fetch(fullURL, {
						...options,
						method,
						headers,
					});

					const data = await retryResponse.json();

					resolve(data.decode);
				} catch (err) {
					reject(err);
				}
			});
		});

		if (!this.isRefreshing) {
			this.isRefreshing = true;

			try {
				const newToken = await authService.refreshToken();
				this.onRefreshed(newToken);
			} catch {
				throw new Error("Session expired");
			} finally {
				this.isRefreshing = false;
			}
		}

		return promise;
	}

	private async request<TData>(
		url: string,
		method: HttpMethod,
		options: RequestOptions = {},
	): Promise<TData> {
		const { accessToken } = authService.getAuthState();
		const fullURL = this.buildURL(url, options.params);
		const headers = new Headers(options.headers);

		headers.set("Content-Type", "application/json");
		headers.set("X-Referer", location.href);

		if (options.auth) {
			if (!accessToken) {
				return this.handleUnauthorized(fullURL, method, options, headers);
			}

			headers.set("Authorization", `Bearer ${accessToken}`);
		}

		const response = await fetch(fullURL, { ...options, method, headers });

		if (response.status === 401 && options.auth) {
			return this.handleUnauthorized(fullURL, method, options, headers);
		}

		if (response.status >= 500) {
			throw new Error(`[${response.status}] Internal server error`);
		}

		const data = (await response.json()) as APIResponse<TData>;

		if (data.type !== "success") {
			if (response.status === 422) {
				throw data.details;
			}

			throw new APIError({
				type: data.type,
				code: data.error ? String(data.error) : "unknown_error",
				message: data.message || "Произошла ошибка",
				advice: data.advice,
				system: data.system,
				error: data.error,
			});
		}

		return data.decode;
	}

	get<TData>(url: string, options?: RequestOptions) {
		return this.request<TData>(url, "GET", options);
	}

	post<TData>(url: string, body = {}, options?: RequestOptions) {
		return this.request<TData>(url, "POST", {
			...options,
			body: JSON.stringify(body),
		});
	}

	put<TData>(url: string, body = {}, options?: RequestOptions) {
		return this.request<TData>(url, "PUT", {
			...options,
			body: JSON.stringify(body),
		});
	}

	delete<TData>(url: string, options?: RequestOptions) {
		return this.request<TData>(url, "DELETE", options);
	}
}

export const apiClient = new HttpClient(getAPIEndpoint());
