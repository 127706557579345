import { apiClient } from "../client";

export type GetSeatList = {
	eventId: number;
	eventTime: string;
	date: string;
	placement: boolean;
	action: {
		id: number;
		name: string;
	};
	venue: {
		id: number;
		name: string;
		address: string;
	};
	tariffPlanList: Array<{
		tariffPlanId: number;
		tariffPlanName: string;
	}>;
	categoryList: Array<{
		availability: number;
		categoryPriceId: number;
		categoryPriceName: string;
		price: number;
		tariffIdMap: Record<number, number>;
	}>;
	categoryLimitList: Array<{
		remainder: number;
		categoryList: Array<number>;
	}>;
	seatsPackage: Array<string[]>;
	availability: number;
	fanIdRequired: boolean;
};

export type ReserveResult = {
	cartTimeout: number;
	currency: string;
	seatList: Array<{
		available: boolean;
		categoryPriceId: number;
		location: { sector: string; row: string; number: string };
		number: string;
		row: string;
		sector: string;
		new: boolean;
		placement: boolean;
		price: number;
		seatId: number;
		error?: string;
	}>;
};

export type UnreserveResult = {
	cartTimeout: number;
	seatList: Array<{
		available: boolean;
		categoryPriceId: number;
		location: { sector: string; row: string; number: string };
		number: string;
		row: string;
		sector: string;
		new: boolean;
		placement: boolean;
		price: number;
		seatId: number;
	}>;
};

export async function getSeatList(args: { eid: string }) {
	return apiClient.post<GetSeatList>("/v2/seat/list", args, {
		auth: true,
	});
}

export async function reserve(args: {
	eid: number;
	categories?: Array<{ cid: number; tid?: number; qty: number }>;
	seats?: Array<{ sid: number; tid?: number; fanid?: string }>;
}) {
	return apiClient.post<ReserveResult>("/v2/cart/reserve", args, {
		auth: true,
	});
}

export async function unreserveAll(args?: { eid?: string }) {
	return apiClient.post<UnreserveResult>("/v2/cart/unreserve_all", args, {
		auth: true,
	});
}

export async function getSchema(args: { eid: string | number }) {
	return apiClient.post<string>("/v2/event/schema", args, {
		auth: true,
	});
}
