import { Suspense } from "react";
import { Outlet } from "react-router";

import { CartProvider } from "@/shared/lib";
import { PageSpinner } from "@/shared/ui/page-spinner";

export const Component: React.FC = () => {
	return (
		<CartProvider>
			<Suspense fallback={<PageSpinner />}>
				<Outlet />
			</Suspense>
		</CartProvider>
	);
};
