import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { BottomSheet } from "@alfalab/core-components/bottom-sheet";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { Space } from "@alfalab/core-components/space";
import { ScrewdriverWrenchBoldMIcon } from "@alfalab/icons-glyph/ScrewdriverWrenchBoldMIcon";

import type { InfoPageProps } from "@/shared/ui/info-page";

import styles from "./Error.module.css";

type BottomSheetErrorProps = Pick<InfoPageProps, "actions"> & {
	icon?: React.ReactNode;
	title?: string;
	message?: string;
	open: boolean;
	onClose(): void;
};

export const BottomSheetError: React.FC<BottomSheetErrorProps> = ({
	open,
	title,
	message,
	onClose,
	actions,
}) => {
	return (
		<BottomSheet
			open={open}
			onClose={onClose}
			actionButton={
				actions && (
					<Space size="m" fullWidth>
						{actions.map((action, idx) => (
							<Button
								key={idx.toString()}
								block
								view={action.view || "secondary"}
								onClick={action.callback}
							>
								{action.label}
							</Button>
						))}
					</Space>
				)
			}
			initialHeight="full"
			hasCloser
			stickyHeader
			usePortal
		>
			<Space align="center" size="l" className={styles.content}>
				<SuperEllipse size={80}>
					<ScrewdriverWrenchBoldMIcon color="var(--color-light-graphic-primary-shade-30)" />
				</SuperEllipse>

				<Space align="center" size="s">
					<Typography.Text view="primary-large" weight="bold">
						{title || "Произошла ошибка"}
					</Typography.Text>

					{message && (
						<Typography.Text view="primary-small">{message}</Typography.Text>
					)}
				</Space>
			</Space>
		</BottomSheet>
	);
};
