import { Spinner } from "@alfalab/core-components/spinner";

import styles from "./PageSpinner.module.css";

export const PageSpinner: React.FC = () => {
	return (
		<div className={styles.container}>
			<Spinner visible preset={48} />
		</div>
	);
};
