import { createContext, useContext } from "react";
import { useNavigate } from "react-router";

import { apiService } from "@/shared/api";

type CitiesContextValue = {
	cities: Array<API.CountryCity & { keys: string[] }>;
	cityId: number;
	setCity: (city: { cityId: number }) => Promise<void>;
};

const CitiesContext = createContext<CitiesContextValue | null>(null);

export function useCitiesContext() {
	const context = useContext(CitiesContext);

	if (!context) {
		throw new Error("Missing CitiesContext.Provider in the tree");
	}

	return context;
}

type CitiesProviderProps = {
	cityId: number;
	cities: API.CountryCity[];
	children: React.ReactNode;
};

export const CitiesProvider: React.FC<CitiesProviderProps> = ({
	cityId,
	cities,
	children,
}) => {
	const navigate = useNavigate();

	const citiesWithKeys = cities.map((city) => ({
		...city,
		keys: [
			city.name,
			...(city.towns ? city.towns.map(({ name }) => name) : []),
		],
	}));

	const setCity: CitiesContextValue["setCity"] = async (city) => {
		await apiService.setCity({ cityId: city.cityId });
		localStorage.setItem("city", String(city.cityId));

		navigate(`/city/${city.cityId}`, { replace: true });
	};

	return (
		<CitiesContext.Provider
			value={{
				cityId,
				cities: citiesWithKeys,
				setCity,
			}}
		>
			{children}
		</CitiesContext.Provider>
	);
};
